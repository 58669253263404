html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-color: #4b4b4b;
  color: #cccccc;
}

body {
  display: flex;
  /* user-select: none; */
}

#root {
  display: flex;
  flex: 1;
}

:root {
  --old-bg-color: #302f2f;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #3f3e3e;
  color: #d8d8d8;
}

.top-panel {
  height: 40px;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #272626;
  padding: 5px;
  align-items: center;
}

.card, .card-view {
  border: 2px solid #272626;
  background-color: #3a3939;
  border-radius: 3px;
  width: 350px;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.card {
  user-select: none;
}

.card-more {
  height: 500px;
}

.cards-container, .card-container {
  flex: 1;
  overflow-y: auto;
  padding: 5px;
}

.cards-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: flex-start;
}

.container--loading {
  opacity: 0.2;
  pointer-events: none;
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card-view {
  flex: 1;
}

.card-menu {
  height: 200px;
  border-top: 1px solid black;
}

.card-header {
  height: 32px;
}

.card-body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.action {
  border: 2px solid #272626;
  background-color: #302f2f;
  text-align: center;
  width: 25px;
  height: 25px;
  user-select: none;
  font-size: 14px;
}

.action:hover {
  cursor: pointer;
}

.thing, .translation, .sentence {
  /* display: flex; */
  /* flex-direction: column; */
  margin-left: 10px;
  /* border-left: 2px dotted #272626; */
  /* padding-left: 3px; */
}

.delete-action {
  background-color: red;
}

.add-action {
  background-color: green;
}

.add-action, .delete-action {
  border-radius: 15px;
}

.card-header, .thing-header, .thing-view-header, .translations-header, .sentences-header {
  display: flex;
  flex-direction: row;
}

.translations-header {
  font-weight: bold;
  font-size: 18px;
}

.thing-view-header {
  font-size: 26px;
  align-items: center;
  justify-content: space-between;
}

.card-actions {
  display: flex;
  align-items: center;
}

.card-title {
  font-size: 20px;
}

.simple-form {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.simple-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-text {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 2px dotted #272626;
  color: #919191;
  font-weight: bold;
  width: 220px;
}

.processed-label {
  width: 2px;
  background-color: green;
  margin-right: 2px;
}

.accept-btn {
  width: auto;
}

.thing-translations {
  flex: 1;
  overflow-y: auto;
}

.thing-footer {
  display: flex;
}

.indicator {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  border: 1px solid #272626;
  border-radius: 4px;
  user-select: none;
}

.card-compact {
  max-height: 150px;
  flex-direction: row;
}

.thing-title:hover {
  cursor: pointer;
}

.empty {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #272626;
}

.diki-preview {
  overflow-y: auto;
  border: solid 1px black;
  height: 180px;
}

.diki-translation {
  text-decoration: underline;
}

.diki-sentence {
  font-size: 13px;
}

.diki-sentence:hover {
  cursor: pointer;
}

.diki-history {
  height: 500px;
  width: 300px;
  overflow-y: auto;
}

.translation-for {
  font-weight: bold;
  border-bottom: solid 5px#272626;
}

.diki-translation:hover, .diki-history-item:hover {
  cursor: pointer;
  text-decoration: none;
}

::-webkit-scrollbar-track
{
    border-radius: 10px;
    background-color: #3f3e3e;
}

::-webkit-scrollbar
{
    width: 12px;
    background-color: #3a3939;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #555;
}